import type AxiosResponseInterceptor from '@/common/interfaces/axios/AxiosResponseInterceptor'
import type {AxiosError} from 'axios'
import type RestResponse from '@/common/data/RestResponse'
import type {Detail} from '@/common/data/RestResponse'
import DetailEventService, {type DetailType} from '@/common/services/DetailEvent/DetailEventService'
import {$inj} from '@/common/decorators/depinject'

const detailEventService = $inj(DetailEventService)

function broadcast(details: Detail[], type: DetailType) {
  details.forEach(detail => {
    // We put these into the microtask queue, so they do not block the main thread as messages aren't needed until
    // after the current operations have completed.
    queueMicrotask(() => detailEventService.publish({...detail, type}))
  })
}

function broadcastAll(restResponse: RestResponse<unknown>) {
  if (!restResponse.data) return
  if (restResponse.data.infos) broadcast(restResponse.data.infos, 'info')
  if (restResponse.data.warnings) broadcast(restResponse.data.warnings, 'warning')
  if (restResponse.data.errors) broadcast(restResponse.data.errors, 'error')
}

function broadcastOrLogError(response: RestResponse<unknown>) {
  try {
    broadcastAll(response)
  } catch (e) {
    console.error('An error occurred while trying to broadcast detail in DetailBroadcastingInterceptor', e)
  }
}

export default class DetailBroadcastingInterceptor implements AxiosResponseInterceptor {
  onFulfilled<T, V>(value: V): Promise<T> | T {
    broadcastOrLogError(value as unknown as RestResponse<unknown>)
    return Promise.resolve(value) as unknown as T
  }

  onRejected(error: AxiosError): Promise<AxiosError> {
    if(error.response) broadcastOrLogError(error.response as RestResponse<unknown>)
    return Promise.reject(error)
  }
}
