<template>
  <div v-if="verbiage">
    <a href="#" @click.prevent="onClick" class="helper-link pointer-cursor">
      <jha-icon-information x-small></jha-icon-information>&nbsp;{{verbiage}}
    </a>
  </div>
  <jha-icon-information
      small
      v-else
      class="pointer-cursor secondary-fill-color"
      @click.prevent.stop="onClick"/>

  <disclosure-dialog :showDisclosureDialog="showDisclosureDialog"
                     @disclosureClosed="closeDisclosureModal"
                     :show-footer="false"
                     :disclosure="disclosure">
  </disclosure-dialog>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {$inj} from "@/common/decorators/depinject";
import {DisclosureFactory, LanguageFactory} from "@/common/services/services.module";
import type {Disclosure} from "@/common/data/Disclosure";

export default defineComponent({
  name: "bltHelperLink",
  props: {
    uiKey: {type: String, required: false},
    disclosureCode: {type: String, required: true},
  },
  data() {
    return {
      showDisclosureDialog: false,
      disclosure: {} as Disclosure,
      verbiage: "",
    }
  },
  setup() {
    const disclosureFactory = $inj(DisclosureFactory);
    const languageFactory = $inj(LanguageFactory);

    return {
      disclosureFactory,
      languageFactory
    }
  },
  async mounted() {
    if (this.uiKey) {
      this.verbiage = await this.languageFactory.get(this.uiKey)
    }
  },
  methods: {
    closeDisclosureModal() {
      this.showDisclosureDialog = false;
    },
    async onClick() {
      this.disclosure = await this.disclosureFactory.get(this.disclosureCode)
      this.showDisclosureDialog = true
    }
  }
})
</script>

<style scoped>
.pointer-cursor {
  cursor: pointer
}

a.helper-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: var(--jh-font-size-300);
  padding: 0 0 var(--jh-size-100) var(--jh-size-400);
  color: var(--jh-color-content-brand-enabled);
  fill: var(--jh-color-content-brand-enabled);
}

a.helper-link > * {
  fill: var(--jh-color-content-brand-enabled);
}
</style>
