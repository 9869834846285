import {injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import type {TypedDetail} from '@/common/data/RestResponse'

@injectable()
@SingletonService
export default class DetailEventService {

  private subscribers: Map<symbol, (detail: TypedDetail) => void> = new Map()

  /**
   * Publishes a detail to all subscribers
   * @param detail -- A RestResponse Detail object with its type
   */
  publish(detail: TypedDetail) {
    this.subscribers.forEach((callback) => callback(detail))
  }

  /**
   * Subscribes to all details that are returned by either HttpResponse or HttpReject responses
   * @param callback
   * @return a function that when called will unregister this subscription.
   */
  subscribe(callback: (detail: TypedDetail) => void): () => void {
   const unsubscribeId = Symbol('unsubscribe')
    this.subscribers.set(unsubscribeId, callback)
    return () => this.subscribers.delete(unsubscribeId)
  }

}
