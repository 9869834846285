<script setup lang="ts">
import type TypeCode from '@/common/data/TypeCode'
import type IAccount from '@/common/models/IAccount'
import type {DeepReadonly} from 'vue'
import {VsgSelect, VsgTextInput} from '@jack-henry/jha-vue-components'

const props = defineProps<{
  intentions: Array<TypeCode>
  account: DeepReadonly<IAccount>
}>()

const emits = defineEmits<{
  (e: 'updateAccount', account: DeepReadonly<IAccount>): void
}>()

const updateIntention = (event: CustomEvent<string>) => {
  emits('updateAccount', {...props.account, accountIntention: event.detail})
}

const updateDescription = (description: string) => {
  emits('updateAccount', {...props.account, description})
}
</script>

<template>
  <div class="two-wide-grid">
    <vsg-select
      :options="intentions"
      empty-option-label=" "
      style="flex: 1 1 50%"
      outline
      @change="updateIntention"
      labelKey="typeDescription"
      valueKey="typeCode"
      :value="account.accountIntention"
      label="Account Intention" />
    <vsg-text-input
      outline
      style="flex: 1 1 50%"
      @blur="updateDescription($event.target.value)"
      label="Account Description"
      :value="account.description" />
  </div>
</template>

<style scoped>
.two-wide-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--jh-space-200);
}
</style>
