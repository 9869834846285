<template>
  <blt-modal-dialog
    :showDialog="showEmailDialog"
    :title="DialogConstant.EMAIL_DIALOG_TITLE"
    showConfirm
    :confirmLabel="emailButton"
    @confirm="emailDisclosures()"
    :confirmDisabled="emailbtnDisable"
    :showFooter="!isFirstScreen"
    :innerScreenBackBtn="!isFirstScreen && context === 'ENROLLMENT'"
    @cancel="cancel()"
    @backButton="back"
    type="small-dialog"
  >
    <template v-slot:dialog-content>
      <div class="email-container">
        <div class="email-disclosure" v-if="context !== 'ENROLLMENT'">
          <div class="description">{{ STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_NOTE }}</div>
          <div class="email-field" v-if="!isMultipleEmailId">
            <div>
              <jha-form-email-input
                :warning="
                  emailValue
                    ? STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_NOT_VALID
                    : STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_REQUIRED
                "
                @change="isEmailValid()"
                @input="onEmailInput"
                v-model="emailValue"
                :valueKey="'emailId'"
                :labelKey="'emailAddress'"
                :label="'Email'"
                :name="'Email'"
                ref="email"
                outline
                required
              />
            </div>
          </div>
          <div class="popup-list" v-if="emailSavedApplicantDisclosurePackage?.disclosures?.length">
            <div
              class="popup-list-item"
              v-for="disclosure in emailSavedApplicantDisclosurePackage.disclosures"
              :key="disclosure.disclosureCode"
            >
              <jha-form-checkbox
                :checked="disclosure.emailActive"
                @change="disclosure.emailActive = !disclosure.emailActive"
                :disabled="!disclosure.allowEmail || isLoading"
              >
                <div class="m-tb-auto">
                  <jha-icon-document
                    class="icon-pos secondary-fill-color"
                    :disabled="!disclosure.allowEmail || isLoading"
                    :class="{ 'disable-item': !disclosure.allowEmail || isLoading }"
                  ></jha-icon-document>
                </div>
                <div class="details" :disabled="!disclosure.allowEmail || isLoading">
                  <div
                    class="primary-text normal-text"
                    :disabled="!disclosure.allowEmail || isLoading"
                    :class="{ 'disable-items': !disclosure.allowEmail || isLoading }"
                  >
                    {{ disclosure?.disclosureTitle }}
                  </div>
                  <div
                    v-if="!disclosure.allowEmail"
                    :disabled="!disclosure.allowEmail || isLoading"
                    class="disclosureAllowEmail"
                    v-blt-ui-text="BranchLanguageKeysConstant.DISCLOSURE_NOT_EMAILABLE_MESSAGE"
                  ></div>
                </div>
              </jha-form-checkbox>
            </div>
          </div>
        </div>
        <jha-slider v-else>
          <jha-slider-content :focusedPane="focusedPane" class="pane-height">
            <jha-slider-pane select-applicant focus class="applicant-pane">
              <div v-if="!isLoading" class="emaildisclosureContent">
                <span>{{ "Select Applicant To Email" }}</span>
                <jha-list>
                  <jha-list-item
                    class="list-item-aligned"
                    v-for="applicant in enrollmentApplicants"
                    :key="applicant.applicantId"
                    @click="selectApplicant(applicant)"
                  >
                    <button type="button" button-reset>
                      <jha-flex-wrapper class="flex-wrapper">
                        <blt-current-applicant-with-status :applicant="applicant || {}" />
                        <jha-icon-chevron-forward small class="chevron-forward"></jha-icon-chevron-forward>
                      </jha-flex-wrapper>
                    </button>
                  </jha-list-item>
                </jha-list>
              </div>
            </jha-slider-pane>
            <jha-slider-pane email-list class="ps-min-height email-list-pane">
              <div class="email-field" v-if="!isMultipleEmailId">
                <div class="EmailContent">{{ STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_NOTE }}</div>
                <jha-form-email-input
                  :warning="
                    emailValue
                      ? STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_NOT_VALID
                      : STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_REQUIRED
                  "
                  @change="isEmailValid()"
                  @input="onEmailInput"
                  v-model="emailValue"
                  :valueKey="'emailId'"
                  :labelKey="'emailAddress'"
                  :label="'Email'"
                  :name="'Email'"
                  ref="email"
                  outline
                  required
                />
              </div>
              <div class="email-drop-down" v-else>
                <div class="EmailContent">
                  {{ "Select an email address to send the disclosures to." }}
                </div>
                <jha-form-select
                  :warning="
                    emailValue
                      ? STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_NOT_VALID
                      : STATE_MSG.EMAIL_DISCLOSURE_DIALOG.EMAIL_REQUIRED
                  "
                  @change="onChangeEmail($event)"
                  :value="emailValue"
                  :valueKey="'emailAddress'"
                  :labelKey="'emailAddress'"
                  :options="applicantEmails"
                  :emptyOptionLabel="'Email'"
                  :disabled="isWorkspaceClosed || applicantEmails.length === 1"
                  ref="emaillist"
                  :label="'Email'"
                  :name="'Email'"
                  outline
                />
              </div>
              <div
                class="disc-list"
                v-for="applicantDisclosurePackage in emailSavedEnrollmentApplicantDisclosurePackage"
                :key="applicantDisclosurePackage.id"
              >
                <blt-current-applicant-with-status
                  :applicant="applicantDisclosurePackage.applicant || {}"
                  :inline="true"
                />
                <div
                  class="popup-list-item"
                  v-for="disclosure in applicantDisclosurePackage.disclosures"
                  :key="disclosure.disclosureCode"
                >
                  <jha-form-checkbox
                    :checked="disclosure.emailActive"
                    @change="disclosure.emailActive = !disclosure.emailActive"
                    :disabled="!disclosure.allowEmail || isLoading"
                  >
                    <div class="m-tb-auto d-flex">
                      <jha-icon-document
                        class="icon-pos secondary-fill-color"
                        :disabled="!disclosure.allowEmail || isLoading"
                        :class="{ 'disable-item': !disclosure.allowEmail || isLoading }"
                      ></jha-icon-document>
                    </div>
                    <div class="details" :disabled="!disclosure.allowEmail || isLoading">
                      <div
                        class="primary-text normal-text"
                        :disabled="!disclosure.allowEmail || isLoading"
                        :class="{ 'disable-items': !disclosure.allowEmail || isLoading }"
                      >
                        {{ disclosure.disclosureTitle }}
                      </div>
                      <div
                        v-if="!disclosure.allowEmail"
                        :disabled="!disclosure.allowEmail || isLoading"
                        class="disclosureAllowEmail"
                        v-blt-ui-text="BranchLanguageKeysConstant.DISCLOSURE_NOT_EMAILABLE_MESSAGE"
                      ></div>
                    </div>
                  </jha-form-checkbox>
                </div>
              </div>
              <div
                class="disc-list"
                v-for="accountDisclosurePackage in emailSavedEnrollmentAccountDisclosurePackage"
                :key="accountDisclosurePackage.id"
              >
                <blt-accounts :account="accountDisclosurePackage"></blt-accounts>
                <div
                  class="popup-list-item"
                  v-for="disclosure in accountDisclosurePackage.disclosures"
                  :key="disclosure.disclosureCode"
                >
                  <jha-form-checkbox
                    :checked="disclosure.emailActive"
                    @change="disclosure.emailActive = !disclosure.emailActive"
                    :disabled="!disclosure.allowEmail || isLoading"
                  >
                    <div class="m-tb-auto">
                      <jha-icon-document
                        class="icon-pos secondary-fill-color"
                        :disabled="!disclosure.allowEmail || isLoading"
                        :class="{ 'disable-item': !disclosure.allowEmail || isLoading }"
                      ></jha-icon-document>
                    </div>
                    <div class="details" :disabled="!disclosure.allowEmail || isLoading">
                    <div
                      class="primary-text normal-text"
                      :disabled="!disclosure.allowEmail || isLoading"
                      :class="{ 'disable-items': !disclosure.allowEmail || isLoading }"
                    >
                      {{ disclosure.disclosureTitle }}
                    </div>
                    <div
                      v-if="!disclosure.allowEmail"
                      :disabled="!disclosure.allowEmail || isLoading"
                      class="disclosureAllowEmail"
                      v-blt-ui-text="BranchLanguageKeysConstant.DISCLOSURE_NOT_EMAILABLE_MESSAGE"
                    ></div>
                  </div>
                  </jha-form-checkbox>
                </div>
              </div>
            </jha-slider-pane>
          </jha-slider-content>
        </jha-slider>
      </div>
      <div v-if="isLoading"><blt-contentloader class="loaderAlignment" :contentLoader="isLoading" /></div>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import type {PropType} from "vue";
import {defineComponent} from "vue";
import type {IEnrollmentAccountPackage} from "@/common/models/IDisclosure";
import "@banno/jha-wc/src/forms/jha-form-email-input/jha-form-email-input.js";
import {DialogConstant} from "@/common/constant/DialogConstant";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import {ApplicantEmailsFactory, SendDisclosureModalFactory} from "@/common/services/services.module";
import {$inj, $injByInterface} from "@/common/decorators/depinject";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import STATE_MSG from "@/common/messages/state.messages";
import REGEX from "@/common/enums/regexEnum";
import type IExtendedEmail from "@/common/data/IExtendedEmail";
import type IWorkspace from "@/common/models/IWorkspace";
import {workspaceStatusConstant} from "@/branchmanager/app/constant/workspaceStatusConstant";
import type IApplicant from "@/common/models/IApplicant";
import {cloneDeep} from "lodash";
import "@banno/jha-wc/src/icons/jha-icon-chevron-forward.js";
import {BranchLanguageKeysConstant} from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";

export default defineComponent({
  name: "bltEmailDisclosureDialog",
  emits: ["emailValueChange", "sendEmailDisclosure", "toggleEmailDisclosure"],
  props: {
    savedApplicantDisclosurePackage: {
      type: Object as PropType<IEnrollmentAccountPackage>,
      default: {} as IEnrollmentAccountPackage
    },
    savedEnrollmentAccountDisclosurePackage: {
      type: Array<IEnrollmentAccountPackage>,
      default: [] as IEnrollmentAccountPackage[]
    },
    savedEnrollmentApplicantDisclosurePackage: {
      type: Array<IEnrollmentAccountPackage>,
      default: [] as IEnrollmentAccountPackage[]
    },
    showEmailDialog: {
      type: Boolean,
      default: false
    },
    email: {
      type: String
    },
    workspaceUUID: {
      type: String,
      required: true
    },
    enrollmentId: {
      type: Number
    },
    enrollmentApplicants: {
      type: Array<IApplicant>
    },
    context: {
      required: true,
      type: String
    }
  },
  setup() {
    const sendDisclosureModalFactory = $inj(SendDisclosureModalFactory);
    const applicantEmailsFactory = $inj(ApplicantEmailsFactory);
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const workflowFactory = $inj(EmployeeWorkflowFactory);


    return {
      workspaceStore,
      sendDisclosureModalFactory,
      applicantEmailsFactory,
      workflowFactory
    };
  },

  data() {
    return {
      emailSavedApplicantDisclosurePackage: {} as IEnrollmentAccountPackage,
      emailSavedEnrollmentApplicantDisclosurePackage: [] as IEnrollmentAccountPackage[],
      emailSavedEnrollmentAccountDisclosurePackage: [] as IEnrollmentAccountPackage[],
      originalEmailSavedEnrollmentApplicantDisclosurePackage: [] as IEnrollmentAccountPackage[],
      originalEmailSavedEnrollmentAccountDisclosurePackage: [] as IEnrollmentAccountPackage[],
      emailListId: [] as IExtendedEmail[],
      isMultipleEmailId: false as boolean,
      emailValid: false as boolean,
      emailValue: "" as string,
      sendType: STATE_MSG.EMP_DISCLOSURE.EMAIL,
      isLoading: false,
      state: STATE_MSG.EMP_DISCLOSURE.LIST,
      emailButton: STATE_MSG.EMP_DISCLOSURE.EMAIL_BUTTON,
      DialogConstant,
      STATE_MSG,
      selectedApplicant: {} as IApplicant,
      applicantEmails: [] as { emailAddress: string; emailId: number; emailType: string; primary: boolean }[],
      focusedPane: "select-applicant" as string,
      isFirstScreen: true,
      BranchLanguageKeysConstant
    };
  },

  async created() {
    this.toggleEmailAll();
    if (this.context === "ENROLLMENT") {
      this.addApplicantsToEnrollmentAccountDisclosures();
      this.state = STATE_MSG.EMP_DISCLOSURE.SELECT_APPLICANT;
    } else {
      this.isFirstScreen = false;
    }
    this.anySelected();
  },
  computed: {
    emailbtnDisable(): boolean {
      return (
        (this.state === STATE_MSG.EMP_DISCLOSURE.LIST ? !this.emailValid || !this.anySelected() : false) ||
        this.isWorkspaceClosed ||
        !this.emailValue ||
        !this.anySelected()
      );
    },
    getConcatPackages(): IEnrollmentAccountPackage[] {
      return [this.savedApplicantDisclosurePackage]
        .concat(this.emailSavedEnrollmentApplicantDisclosurePackage)
        .concat(this.emailSavedEnrollmentAccountDisclosurePackage);
    },
    currentWorkspace(): IWorkspace {
      return this.workspaceStore.workspace;
    },
    isWorkspaceClosed(): boolean {
      return this.currentWorkspace?.statusCode === workspaceStatusConstant.CLOSED;
    }
  },
  watch: {
    email(newVal) {
      this.emailValue = newVal;
    },
    emailList(newVal) {
      this.emailListId = newVal;
      if (this.emailListId.length > 1) {
        this.isMultipleEmailId = true;
        this.emailValue = this.emailListId[0].emailAddress;
        this.emailValid = true;
      } else {
        this.isMultipleEmailId = false;
      }
    },
    savedApplicantDisclosurePackage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.toggleEmailAll();
      }
    }
  },

  methods: {
    toggleEmailAll(): void {
      this.emailSavedApplicantDisclosurePackage = this.savedApplicantDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllEmailActive([this.savedApplicantDisclosurePackage])[0]
        : ({} as IEnrollmentAccountPackage);
      this.emailSavedEnrollmentAccountDisclosurePackage = this.savedEnrollmentAccountDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllEmailActive(this.savedEnrollmentAccountDisclosurePackage)
        : ([] as IEnrollmentAccountPackage[]);
      this.emailSavedEnrollmentApplicantDisclosurePackage = this.savedEnrollmentApplicantDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllEmailActive(this.savedEnrollmentApplicantDisclosurePackage)
        : ([] as IEnrollmentAccountPackage[]);
      this.originalEmailSavedEnrollmentAccountDisclosurePackage = cloneDeep(
        this.emailSavedEnrollmentAccountDisclosurePackage
      );
      this.originalEmailSavedEnrollmentApplicantDisclosurePackage = cloneDeep(
        this.emailSavedEnrollmentApplicantDisclosurePackage
      );
    },
    addApplicantsToEnrollmentAccountDisclosures(): void {
      this.sendDisclosureModalFactory
        .addApplicantIdsToEnrollmentAccountDisclosures(
          this.savedEnrollmentAccountDisclosurePackage,
          this.workspaceUUID,
          this.enrollmentId || 0
        )
        .then((response) => {
          this.emailSavedEnrollmentAccountDisclosurePackage = response;
          this.originalEmailSavedEnrollmentAccountDisclosurePackage = cloneDeep(
            this.emailSavedEnrollmentAccountDisclosurePackage
          );
        });
    },
    onEmailInput(): void {
      this.$emit("emailValueChange", this.emailValue);
    },
    isEmailValid(): void {
      this.emailValid = (this.$refs.email as any).checkValidity() && this.emailValue !== "";
    },
    anySelected(): boolean {
      return this.sendDisclosureModalFactory.anySelected(this.getConcatPackages, this.sendType);
    },
    selectApplicant(applicant: IApplicant) {
      this.isLoading = true;
      this.selectedApplicant = applicant;
      this.isFirstScreen = false;
      this.loadApplicantEmails();
      this.emailSavedEnrollmentApplicantDisclosurePackage =
        this.sendDisclosureModalFactory.filterEnrollmentApplicantDisclosurePackageByApplicant(
          this.emailSavedEnrollmentApplicantDisclosurePackage,
          applicant
        );
      this.emailSavedEnrollmentAccountDisclosurePackage =
        this.sendDisclosureModalFactory.filterEnrollmentAccountDisclosurePackageByApplicant(
          this.emailSavedEnrollmentAccountDisclosurePackage,
          applicant
        );
      this.state = STATE_MSG.EMP_DISCLOSURE.LIST;
    },
    emailDisclosures(): void {
      if (!this.anySelected()) {
        return;
      }
      this.workflowFactory.setBeginPreloadingStatus();
      let emailPromise;
      if (!this.enrollmentId) {
        emailPromise = this.sendDisclosureModalFactory.emailApplicantDisclosurePackage(
          this.workspaceUUID,
          this.emailValue,
          this.emailSavedApplicantDisclosurePackage
        );
      } else {
        emailPromise = this.sendDisclosureModalFactory.emailEnrollmentDisclosurePackages(
          this.workspaceUUID,
          this.selectedApplicant.applicantId,
          this.enrollmentId || this.workspaceStore.enrollment.enrollmentId,
          this.emailValue,
          this.getConcatPackages
        );
      }
      emailPromise
        .then(
          () => {
            this.$emit("sendEmailDisclosure");
          },
          () => {
            this.cancel();
          }
        )
        .finally(() => {
          this.workflowFactory.setEndPreloadingStatus();
        });
    },
    onChangeEmail(value: { detail: string }): void {
      this.emailValue = value.detail;
      this.emailValid = (this.$refs.emaillist as any).checkValidity();
      this.$emit("emailValueChange", this.emailValue);
    },
    loadApplicantEmails(): void {
      this.applicantEmailsFactory
        .getApplicantEmailList(this.workspaceUUID, this.selectedApplicant.applicantId)
        .then((response: [{ emailAddress: string; emailId: number; emailType: string; primary: boolean }]) => {
          this.applicantEmails = response;
          this.isMultipleEmailId = !!response.length;
          this.$nextTick(() => {
            this.emailValue = this.isMultipleEmailId ? response[0].emailAddress : "";
            this.emailValid = this.emailValue ? true : false;
            this.$emit("emailValueChange", this.emailValue);
            this.isLoading = false;
            this.setFocusPane("email-list");
          });
        });
    },
    cancel() {
      this.$emit("toggleEmailDisclosure");
      if (this.context === "ENROLLMENT") {
        this.state = STATE_MSG.EMP_DISCLOSURE.SELECT_APPLICANT;
        this.setFocusPane("select-applicant");
      }
    },
    setFocusPane(focusPane: string): void {
      this.focusedPane = focusPane;
    },
    back() {
      this.isFirstScreen = true;
      this.emailSavedEnrollmentAccountDisclosurePackage = this.originalEmailSavedEnrollmentAccountDisclosurePackage;
      this.emailSavedEnrollmentApplicantDisclosurePackage = this.originalEmailSavedEnrollmentApplicantDisclosurePackage;
      this.setFocusPane("select-applicant");
    }
  }
});
</script>

<style scoped>
.loaderAlignment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.email-container {
  padding: 24px 0 24px 24px;
  width: 100%;
  --jha-list-item-margin-left: 0px;
  --jha-list-item-vertical-spacing: var(--jh-space-400);
  --jha-list-item-horizontal-spacing: 0px;
  max-height: calc(100vh - 222px);
  overflow-y: auto;
}
.email-list-pane {
  --secondary-content-background-color: #f5f5f5;
}
.disable-items{
    --jha-color-primary: var(--jha-text-dark)!important;
  opacity:0.3;
  color: var(--jha-text-dark)!important;
}

.disable-item{
    fill: var(--jha-text-dark)!important;
  opacity:0.3;
}
.applicant-pane {
  --secondary-content-background-color: #ffffff;
}
.email-container .applicant-pane jha-list-item {
  padding-right: 24px;
}
.email-container .applicant-pane jha-list {
  margin-top: 16px;
}

.description {
  padding-bottom: var(--jh-space-100);
  color: var(--jha-text-dark);
}
.popup-list {
  max-height: 42vh;
  overflow: auto;
}

.disclosureAllowEmailFinalDisclosure {
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: var(--body-text-secondary-color);
}

.EmailContent {
  padding-top: 16px;
  padding-bottom: 4px;
}

.popup-list-item {
  border-bottom: 1px solid var(--jha-border-color);
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.popup-list-item jha-form-checkbox {
  width: 100%;
  font-size: 14px;
}
jha-form-checkbox {
  --jha-form-checkbox-padding: var(--jh-space-400) var(--jh-space-600) var(--jh-space-400) 0px;
  --jha-form-checkbox-font-size: 14px;
  margin-bottom: 0px;
}
.email-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.popup-list-item:last-child {
  border-bottom: 0px solid;
}
.email-field {
  padding-right: var(--jh-space-600);
  padding-bottom: var(--jh-space-400);
}
.email-drop-down {
  padding-right: var(--jh-space-600);
  padding-bottom: var(--jh-space-400);
  --jha-text-input-margin-bottom: 0px;
}
.disc-list {
  padding-bottom: var(--jh-space-400);
}
.disc-list:last-child {
  padding-bottom: 0px;
}

.emaildisclosureContent {
  padding-top: 16px;
}
.disclosureAllowEmail {
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
  color: var(--body-text-secondary-color);
}
@media only screen and (max-width: 468px) {
  .email-container {
    padding: 16px 0 16px 16px !important;
    max-height: calc(100vh - 132px);
  }
  .disclosureAllowEmail {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    color: var(--body-text-secondary-color);
  }
  .disclosureAllowEmailFinalDisclosure {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
    color: var(--body-text-secondary-color);
  }
  .email-container .applicant-pane jha-list-item {
    padding-right: 16px;
  }
  .popup-list-item {
    margin-left: 0;
    padding-right: 16px;
  }
  .email-field {
    padding-right: var(--jh-space-400);
    padding-bottom: var(--jh-space-400);
  }
  .email-drop-down {
    padding-right: var(--jh-space-400);
  }
  jha-form-checkbox {
  --jha-form-checkbox-padding: var(--jh-space-400) 0px var(--jh-space-400) 0px;
  }
}
</style>
<style>
.email-container .applicant-pane .current-applicant-wrapper {
  width: auto;
  flex: 1;
}
.disclosure-heading .header .head-icon-padding {
  width: 24px;
  height: 24px;
}
.email-container .info .title {
  display: none;
}
.email-container .current-applicant-wrapper .current-applicant-header,
.email-container .disc-list .disclosure-heading {
  margin: 0px 24px 0 0;
  --jha-icon-fill-color: var(--body-text-secondary-color);
  padding: 0px;
}
.email-container .email-list-pane .current-applicant-wrapper .current-applicant-header,
.email-container .email-list-pane .disc-list .disclosure-heading {
  padding: 8px;
}
.email-container .email-list-pane .applicant-details {
  display: flex;
  align-items: center;
  gap: 8px;
}
.email-container .applicant-pane .applicant-details .primary-text {
  padding-top: 3px;
  color: var(--jh-color-content-primary-enabled);
  max-width: calc(100vw - 100px);
}
.email-container .email-list-pane .applicant-details .primary-text {
  padding-top: 2px;
  color: var(--body-text-secondary-color);
  font-size: 12px;
}
.email-container .applicant-pane .applicant-details .secondary-text {
  color: var(--jh-color-content-secondary-enabled);
}
.email-container .applicant-icons > div {
  padding-right: 8px;
}

.email-container .email-list-pane jha-icon-user {
  height: 20px;
  width: 20px;
}

.email-container .applicant-details .primary-text,
.email-container .applicant-details .secondary-text {
  max-width: calc(100vw - 132px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.email-container .applicant-pane .current-applicant-wrapper .current-applicant-header,
.email-container .applicant-pane .disc-list .disclosure-heading {
  margin: 0px;
}
@media screen and (max-width: 468px) {
  .email-container .email-list-pane .applicant-details {
    /* flex-direction: column; */
    align-items: center;
    gap: 2px;
  }
  .email-container .current-applicant-wrapper .current-applicant-header,
  .email-container .disc-list .disclosure-heading {
    margin: 0px 16px 0 0;
  }
  .email-container .applicant-pane .current-applicant-wrapper .current-applicant-header,
  .email-container .applicant-pane .disc-list .disclosure-heading {
    margin: 0px;
  }

  .email-container .applicant-details .primary-text,
  .email-container .applicant-details .secondary-text {
    max-width: calc(100vw - 100px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
