<script setup lang="ts">
import type IAccount from '@/common/models/IAccount'
import {computed} from 'vue'
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {VsgIconCheck, VsgIconDate, VsgIconHeartbeat, VsgIconPiggyBank} from '@jack-henry/jha-vue-components'
import ProductSelectionIraIcon from '@/common/components/productSelection/product-selection-ira-icon.vue'
import TFA_GROUPS from "@/common/components/productSelection/TfaGroupsEnum";

const props = defineProps<{
  account: IAccount
}>()

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const accountType = computed(() =>
  injection.values.productList.value.find((product) => product.productId === props.account.accountTypeId)
)

const icon = computed(() => {
  if (accountType.value?.subgroup) {
    switch (accountType.value.subgroup) {
      case TFA_GROUPS.SINGLE_HSA:
      case TFA_GROUPS.FAMILY_HSA:
        return VsgIconHeartbeat
      case TFA_GROUPS.ROTH_IRA:
      case TFA_GROUPS.TRADITIONAL_IRA:
        return ProductSelectionIraIcon
    }
  } else if (accountType.value?.group) {
    switch (accountType.value.group) {
      case 'CHECKING':
      default:
        return VsgIconCheck
      case 'SAVINGS':
        return VsgIconPiggyBank
      case 'CD':
        return VsgIconDate
    }
  }
})
</script>
<template>
  <div class="product-selection-account-icon">
    <component :is="icon" />
  </div>
</template>

<style scoped>
.product-selection-account-icon {
  margin-right: var(--jh-space-200);
  display: flex;
}

.product-selection-account-icon > * {
  fill: var(--jha-color-primary);
}
</style>
