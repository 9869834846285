import type {Directive} from 'vue'
import {debounce} from 'lodash'

const onInputDebounced = <Directive<HTMLElement, Function>> {

  mounted(el, binding) {

    let delay = 200;

    if(binding.arg) {
      try {
        delay = parseInt(binding.arg);
      } catch(e) {
        console.error("onInputDebounced: invalid argument, expected a number, got", binding.arg, el)
      }
    }

    try {
      const debouncedFn = debounce((e) => binding.value(e), delay)
      el.addEventListener('input', debouncedFn)
    } catch (e) {
      console.error("onInputDebounced: error adding input event listener", e, binding.value, el)
    }
  }

}

export default onInputDebounced

