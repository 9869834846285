<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {computed, onMounted, ref} from 'vue'
import {$inj, $injByInterface} from '@/common/decorators/depinject'
import IWorkspaceStore from '@/common/services/Workspace/IWorkspaceStore'
import type {IFundingType} from '@/common/models/IFunding'
import ProductSelectionFundingIntentionSubgroup from '@/common/components/productSelection/product-selection-funding-intention-subgroup.vue'
import type {IAccountTypeGroup} from '@/common/services/Products/accountGroupService'
import TFA_GROUPS from '@/common/components/productSelection/TfaGroupsEnum'
import {FundingIntentionService} from '@/common/services/Products/fundingIntentionService'
import {VsgButton} from '@jack-henry/jha-vue-components'
import {default as BltFormValidation} from '@/common/components/bltForm/bltForm.vue'

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const fundingIntentionService = $inj(FundingIntentionService)

const workspaceStore = $injByInterface<IWorkspaceStore>('IWorkspaceStore')

const bltFormRef = ref<HTMLFormElement>()
const fundingTypes = ref<Map<number, Array<IFundingType>>>(new Map())
const accountIdsInGroup = ref<Map<string, Array<number>>>(new Map())

const tfaSubgroups = computed(() => {
  return Object.keys(TFA_GROUPS).reduce((acc, cur) => {
    const group = injection.values.productGroups.value.find((group) => group.typeCode == cur)

    if (group) {
      const accountsInGroup = injection.values.accountList.value.filter((account) => account.accountGroup === cur)

      if (accountsInGroup.length) {
        acc.push(group)
        accountIdsInGroup.value.set(
          cur,
          accountsInGroup.map((account) => account.accountId)
        )
      }
    }

    return acc
  }, [] as Array<IAccountTypeGroup>)
})

const uniqueAccountIds = computed(() => {
  return injection.values.accountList.value.reduce((acc, cur) => {
    acc.add(cur.accountTypeId)
    return acc
  }, new Set() as Set<number>)
})

const saveIntentions = () => {
  injection.methods.saveIntentions()
}

const submitForm = () => {
  setTimeout(() => {
    bltFormRef.value?.form.dispatchEvent(new Event('submit', {cancelable: true}))
  }, 100)
}
</script>
<template>
  <span>
    To open your plan(s), we need to gather some important details about funding intention. Providing this information
    ensures that your contributions are processed smoothly and in compliance with tax regulations. Please note that you
    will be able to actually fund the individual account(s) later in the process.
  </span>

  <blt-form-validation
    @validSubmit="saveIntentions"
    ref="bltFormRef"
    :form-style="{width: '100%'}">
    <product-selection-funding-intention-subgroup
      v-for="subgroup in tfaSubgroups"
      :subgroup
      :fundingTypes
      :accountIdsInGroup="accountIdsInGroup.get(subgroup.typeCode)"
      :key="subgroup.typeCode" />
  </blt-form-validation>

  <vsg-button
    v-if="injection.channel == 'open'"
    @click="submitForm"
    block
    sync>
    Continue
  </vsg-button>
</template>
