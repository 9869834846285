import {inject, injectable} from "inversify";
import {SingletonService} from "@/common/dependency.configs";
import HttpHandler from "@/common/services/connect/HttpHandler";
import type RestResponse from "@/common/data/RestResponse";

@injectable()
@SingletonService
export class FundingIntentionService {
    constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {
    }

    getAll(workspaceUUID: string, enrollmentId: number): Promise<RestResponse<Array<FundingIntention>>> {
        return this.httpHandler.get(`{{boltsbranch}}/workspace/${workspaceUUID}/enrollment/${enrollmentId}/funding-intention`)
    }

    save(workspaceUUID: string, enrollmentId: number, dto: FundingIntention) : Promise<RestResponse<FundingIntention>> {
        return dto.fundingIntentionUuid
            ? this.put(workspaceUUID, enrollmentId, dto)
            : this.post(workspaceUUID, enrollmentId, dto)
    }

    post(workspaceUUID: string, enrollmentId: number, dto: FundingIntention): Promise<RestResponse<FundingIntention>> {
        return this.httpHandler.post(`{{boltsbranch}}/workspace/${workspaceUUID}/enrollment/${enrollmentId}/funding-intention/${dto.subgroupCode}`, dto)
    }

    put(workspaceUUID: string, enrollmentId: number, dto: FundingIntention): Promise<RestResponse<FundingIntention>> {
        return this.httpHandler.put(`{{boltsbranch}}/workspace/${workspaceUUID}/enrollment/${enrollmentId}/funding-intention/${dto.subgroupCode}`, dto)
    }
}

export interface FundingIntention {
    fundingIntentionUuid?: string,
    subgroupCode: string,
    fundingMethod?: string,
    contributionYear?: string,
    contributionAmount?: number
}