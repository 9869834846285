import {inject, injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'
import type TypeCode from '@/common/data/TypeCode'
import type {Ref} from '@vue/reactivity'
import type {InjectionKey} from 'vue'
import type {IAccountTypeGroup} from "@/common/services/Products/accountGroupService";

@injectable()
@Service
export class ProductsSubgroupService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(groupList: Array<IAccountTypeGroup>, productGroup: string): Array<IAccountTypeGroup> {
      return groupList.filter(group => group.parentGroup === productGroup)
  }
}