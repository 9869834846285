import HttpHandler from '@/common/services/connect/HttpHandler'
import {inject, injectable} from 'inversify'
import LookupFactory from '@/common/services/Lookup/LookupFactory'
import ApplicantFactory from '@/common/services/Applicant/applicantFactory'
import ResponseTypes from '@/common/enums/responseTypesEnum'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'
import {LOOKUPSUPPORTCONSTANTS} from '@/common/constant/LookupConstant'
import type {Product} from '@/common/models/IProductSelection'
import {Service} from "@/common/dependency.configs";
import type TypeCode from "@/common/data/TypeCode";

@injectable()
@Service
class ProductLookupFactory {
  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(ApplicantFactory) private applicantFactory: ApplicantFactory
  ) {}

  getProductInfo(productId: string) {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/product/${productId}/include=${LOOKUPSUPPORTCONSTANTS.PRODUCT_FEATURES}`,
        {},
        ResponseTypes.Payload
      )
      .then(function (response) {
        return response.productFeatures
      })
  }

  // @NOTE: This makes a one call for all products, regardless of group
  getAllRegionalProducts(workspaceUUID: string, enrollmentId: number): Promise<Array<Product>> {
    return this.applicantFactory.getAll(workspaceUUID, {isOriginator: true}).then((response) => {
      return this.lookupFactory.Product?.getAll(
          {
            enabled: true,
            applicantId: response[0] ? response[0].applicantId : undefined,
          },
          {cache: false},
          {
            workspace: workspaceUUID,
            enrollment: enrollmentId
          }
      )
    })
  }
}

export default ProductLookupFactory
