import {inject, type InjectionKey} from 'vue'

/**
 *   @param injectionKey the injection key that will be passed to vue's native inject
 *
 *   this will either resolve the desired injection, or throw an exception detailing what was
 *   expected, but could not be resolved.
 *
 *   this should be used anywhere that we always expect an injection to be defined and gives
 *   us non-nullable type safety on the result of the injection.
 *
 */
export function requireInject<T>(injectionKey: InjectionKey<T>): T {

  const injection = inject(injectionKey);

  if(!injection) throw new Error(`Expected to inject provided data for ${injectionKey.description}, but received undefined`);

  return injection;

}
