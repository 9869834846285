import {inject, injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'
import {AxiosHeaders} from "axios";

@injectable()
@Service
export class ProductsSubgroupTaxplanService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(typeCode: string): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.get(`{{boltsbranch}}/tax-plan/${typeCode}`)
  }

  put(taxPlanId: string, dto: ProductsSubgroupTaxplan): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.put(`{{boltsbranch}}/tax-plan/${taxPlanId}`, dto)
  }
}

/**
 * @DEBUG
 * This StubRepository doesn't have a POST call so the get calls with always return [].
 * The function below adds in some junk data to make the UI usable until this is added in.
 */
const MAKE_PRODUCT_SUBGROUP_TAXPLAN: () => ProductsSubgroupTaxplan = () => ({
  typeCode: randomString(),
  subgroupCode: randomString(),
  enabled: true,
  displayOrder: 1,
  disclosureCode: 'ETAAD',
  typeDescription: randomString(),
  previousYearContribCutoffDate: '2024-11-01',
  validateFundingIntentionContribLimits: true,
  validateFundingContribLimits: true,
  currentYearContribLimit: 1,
  previousYearContribLimit: 2,
  currentYearCatchupContribLimit: 3,
  previousYearCatchupContribLimit: 4,
  currentYearCatchupAge: 5,
  previousYearCatchupAge: 6,
})
//funky 😎
const randomString = () => {
  return Math.floor(Math.random() * Date.now()).toString(36)
}

export interface ProductsSubgroupTaxplan {
  typeCode: string
  subgroupCode: string
  enabled: boolean
  typeDescription: string
  displayOrder: number
  disclosureCode?: string
  previousYearContribCutoffDate: string
  validateFundingIntentionContribLimits: boolean
  validateFundingContribLimits: boolean
  currentYearContribLimit: number
  previousYearContribLimit: number
  currentYearCatchupContribLimit: number
  previousYearCatchupContribLimit: number
  currentYearCatchupAge: number
  previousYearCatchupAge: number
}
