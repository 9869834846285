<script setup lang="ts">
import {$inj, $injByInterface} from '@/common/decorators/depinject'
import {
  EnrollmentAccountFactory,
  EnrollmentValidationFactory,
  LanguageFactory,
  LookupFactory,
  WorkflowFactory
} from '@/common/services/services.module'
import {computed, DeepReadonly, onMounted, provide, readonly, ref} from 'vue'
import type TypeCode from '@/common/data/TypeCode'
import {ProductsSubgroupService} from '@/common/services/Products/productsSubgroupService'
import {PRODUCT_SELECTION_INJECTION, ProductSelectionPanes} from '@/common/services/Products/productSelectionInjection'
import {
  VsgProgress,
  VsgSearchInput, vsgShowToast,
  VsgSlider,
  VsgSliderContent,
  VsgSliderPane,
  VsgToast
} from '@jack-henry/jha-vue-components'
import ProductGroupsPane from '@/common/components/productSelection/productGroupsPane.vue'
import IWorkspaceStore from '@/common/services/Workspace/IWorkspaceStore'
import type {Product} from '@/common/models/IProductSelection'
import ProductSelectedSubgroup from '@/common/components/productSelection/productSelectedSubgroup.vue'
import {
  ProductsSubgroupTaxplan,
  ProductsSubgroupTaxplanService
} from '@/common/services/Products/productsSubgroupTaxplanService'
import type IAccount from '@/common/models/IAccount'
import ProductSelectionSelectedAccountList from '@/common/components/productSelection/productSelectionSelectedAccountList.vue'
import {WorkflowStatesConstant} from '@/common/constant/WorkflowStatesConstant'
import ProductSelectionFundingIntention from '@/common/components/productSelection/product-selection-funding-intention.vue'
import bltEmpForm from '@/branchmanager/app/components/bltForm/bltEmpForm.vue'
import type IEmpWorkspaceStore from '@/branchmanager/stores/IEmpWorkspaceStore'
import BltAddAdditionalButton from '@/common/components/bltAddAdditionalButton/bltAddAdditionalButton.vue'
import ShadowDomService from '@/common/components/bltForm/shadowDomService'
import ProductLookupFactory from "@/common/services/Lookup/ProductLookupFactory";
import type {IAccountTypeGroup} from "@/common/services/Products/accountGroupService";
import AccountGroupService from "@/common/services/Products/accountGroupService";
import BltEmpForm from "@/branchmanager/app/components/bltForm/bltEmpForm.vue";
import TFA_GROUPS from "@/common/components/productSelection/TfaGroupsEnum";
import {FundingIntention, FundingIntentionService} from "@/common/services/Products/fundingIntentionService";
import EnrollmentApplicantFactory from "@/common/services/Enrollment/enrollmentApplicantFactory";
import IApplicant from "@/common/models/IApplicant";

const productLookupFactory = $inj(ProductLookupFactory)
const productsSubgroupService = $inj(ProductsSubgroupService)
const productsSubgroupTaxplanService = $inj(ProductsSubgroupTaxplanService)
const lookupFactory = $inj(LookupFactory)
const enrollmentAccountFactory = $inj(EnrollmentAccountFactory)
const languageFactory = $inj(LanguageFactory)
const enrollmentValidationFactory = $inj(EnrollmentValidationFactory)
const workflowFactory = $inj(WorkflowFactory)
const shadowDomService = $inj(ShadowDomService)
const accountGroupService = $inj(AccountGroupService)
const fundingIntentionService = $inj(FundingIntentionService)
const enrollmentApplicantFactory = $inj(EnrollmentApplicantFactory
)
const workspaceStore = $injByInterface<IEmpWorkspaceStore>('IWorkspaceStore')

const productGroups = ref<Array<TypeCode>>([])
const productList = ref<Array<Product>>([])
const enrollmentApplicants = ref<Array<IApplicant>>([])
const productSubgroups = ref<Map<string, Array<IAccountTypeGroup>>>(new Map())
const productSubgroupTaxPlans = ref<Map<string, ProductsSubgroupTaxplan>>(new Map())
const focusedPane = ref<ProductSelectionPanes>('PRODUCT_GROUPS')
const selectedSubgroup = ref<IAccountTypeGroup>()
const loading = ref(true)
const accountList = ref<Array<IAccount>>([])
const showSearchBar = ref(false)
const searchTerm = ref<string>('')
const channel = 'branch' as 'open' | 'branch'
const fundingIntentions = ref<Array<FundingIntention>>([])

onMounted(() => {
  Promise.all([
    productLookupFactory.getAllRegionalProducts(
      workspaceStore.workspace.workspaceUUID,
      workspaceStore.enrollment.enrollmentId
    ),
    accountGroupService.getAll({enabled: true}),
    enrollmentAccountFactory.getEnrollmentAccountList(
      workspaceStore.workspace.workspaceUUID,
      workspaceStore.enrollment.enrollmentId
    ),
    fundingIntentionService.getAll(workspaceStore.workspaceUUID, workspaceStore.enrollment.enrollmentId),
    enrollmentApplicantFactory.getEnrollmentApplicantList(
        workspaceStore.workspace.workspaceUUID,
        workspaceStore.enrollment.enrollmentId
    )
  ])
    .then(([productListRes, productGroupsRes, accountListRes, fundingIntentionRes, enrollmentApplicantListRes]) => {
      productGroups.value = productGroupsRes.data.payload
      productList.value = productListRes
      accountList.value = accountListRes ?? []
      fundingIntentions.value = fundingIntentionRes.data.payload
      enrollmentApplicants.value = enrollmentApplicantListRes

      if (accountList.value.length) {
        focusedPane.value = 'SELECTED_ACCOUNT_LIST'
      }

      return getProductSubgroups()
    })
    .finally(() => {
      loading.value = false
    })
})

const getProductSubgroups = () => {
  return Promise.all(
      productGroups.value.map((group) => {
        const subgroups = productsSubgroupService.get(productGroups.value, group.typeCode)
        productSubgroups.value.set(group.typeCode, subgroups)

        return Promise.all(
            subgroups.map((subgroup) => {
              return productsSubgroupTaxplanService.get(subgroup.typeCode).then((response) => {
                productSubgroupTaxPlans.value.set(subgroup.typeCode, response.data.payload)
              })
            })
        )
      })
  )
}

const alternateBackEnabled = computed(() => {
  return focusedPane.value !== 'SELECTED_ACCOUNT_LIST' && accountList.value.length > 0
})

const alternateBack = () => {
  focusedPane.value = 'SELECTED_ACCOUNT_LIST'
}

const addProduct = (product: Product) => {
  return enrollmentAccountFactory
    .postEnrollmentAccount(workspaceStore.workspace.workspaceUUID, workspaceStore.enrollment.enrollmentId, product)
    .then((response) => {
      console.log(response, accountList.value)
      accountList.value.push(response)
      focusedPane.value = 'SELECTED_ACCOUNT_LIST'
      return response
    })
}

const deleteAccount = (account: DeepReadonly<IAccount>) => {
  return enrollmentAccountFactory
    .deleteEnrollmentAccount(
      workspaceStore.workspace.workspaceUUID,
      workspaceStore.enrollment.enrollmentId,
      account.accountId
    )
    .then(() => {
      accountList.value = accountList.value.filter((x) => x.accountId !== account.accountId)

      if (accountList.value.length == 0) {
        focusedPane.value = 'PRODUCT_GROUPS'
      }
    })
}

const addAnotherAccount = () => {
  focusedPane.value = 'PRODUCT_GROUPS'
}

const updateAccount = (account: DeepReadonly<IAccount>) => {
  return enrollmentAccountFactory.putEnrollmentAccount(
      workspaceStore.workspace.workspaceUUID,
      workspaceStore.enrollment.enrollmentId,
      account as IAccount
  )
}

const saveIntentions = () => {
  return Promise.all(
      fundingIntentions.value.map((intention) => {
        return fundingIntentionService.save(
            workspaceStore.workspaceUUID,
            workspaceStore.enrollment.enrollmentId,
            intention
        )
      })
  )
}

provide(PRODUCT_SELECTION_INJECTION, {
  values: {
    productList: readonly(productList),
    searchTerm: readonly(searchTerm),
    accountList: readonly(accountList),
    productGroups: readonly(productGroups),
    productSubgroups: readonly(productSubgroups),
    productSubgroupTaxPlans: readonly(productSubgroupTaxPlans),
    selectedSubgroup,
    fundingIntentions,
    enrollmentApplicants
  },
  methods: {
    goToPane: (pane: ProductSelectionPanes) => {
      focusedPane.value = pane
    },
    addProduct,
    deleteAccount,
    updateAccount,
    saveIntentions
  },
  channel
})

const secondaryActionText = computed(() => {
  if (focusedPane.value == 'SELECTED_ACCOUNT_LIST') {
    return channel == 'open'
      ? languageFactory.getSynchronous(`${channel}_product_selection_add_button`)
      : 'Add a new account'
  }
})

const showContinueButton = computed(() => accountList.value.length > 0 && focusedPane.value == 'SELECTED_ACCOUNT_LIST')

const hideInfoBlock = computed(() => focusedPane.value == 'SELECTED_ACCOUNT_LIST')

const hasAnyTfaAccounts = computed(() => {
  let result = false

  for (const account of accountList.value) {
    const product = productList.value.find((product) => product.productId === account.accountTypeId)
    if (Object.keys(TFA_GROUPS).includes(product?.group ?? '')) {
      result = true
      break
    }
  }

  return result
})

const showSearchIcon = computed(() => focusedPane.value == 'PRODUCT_GROUPS')


const searchBarElem = ref<HTMLElement>()
const toggleSearchBar = () => {
  showSearchBar.value = !showSearchBar.value
  /**
   * @TODO: Focus the searchbar
   */
}

const hideContinue = computed(() =>
  focusedPane.value !== 'SELECTED_ACCOUNT_LIST'
    && focusedPane.value !== 'FUNDING_INTENTION'
)

const beforeContinue = () => saveIntentions()
</script>
<template>
  <blt-emp-form
    :searchIcon="showSearchIcon"
    @searchBtnClicked="toggleSearchBar"
    :workspace-uuid="workspaceStore.workspace.workspaceUUID"
    :enrollment-id="workspaceStore.enrollment.enrollmentId"
    :before-continue="beforeContinue"
    validation="PRODUCT_SELECTION"
    validation-type="ENROLLMENT"
    innerScreenButtonEnabled="true"
    :secondaryActionText
    :hideNavigation="hideContinue"
    :hideContinueButton="hideContinue"
    :loading
    :innerScreenBtnFunc="addAnotherAccount"
    :hideInfoBlock
    :alternateBackEnabled
    :alternate-back-func="alternateBack">
    <vsg-search-input
      v-model="searchTerm"
      ref="searchBarElem"
      outline
      label="Product"
      v-show="showSearchBar" />

    <vsg-progress
      card
      v-if="loading" />
    <vsg-slider v-else>
      <vsg-slider-content :focusedPane>
        <vsg-slider-pane PRODUCT_GROUPS>
          <product-groups-pane v-if="focusedPane == 'PRODUCT_GROUPS'" />
        </vsg-slider-pane>
        <vsg-slider-pane PRODUCT_SUBGROUP>
          <product-selected-subgroup v-if="focusedPane == 'PRODUCT_SUBGROUP'" />
        </vsg-slider-pane>
        <vsg-slider-pane SELECTED_ACCOUNT_LIST>
          <product-selection-selected-account-list v-if="focusedPane == 'SELECTED_ACCOUNT_LIST'" />
        </vsg-slider-pane>
        <vsg-slider-pane FUNDING_INTENTION>
          <product-selection-funding-intention v-if="focusedPane == 'FUNDING_INTENTION'" />
        </vsg-slider-pane>
      </vsg-slider-content>
    </vsg-slider>

    <br />

    <blt-add-additional-button
      v-if="secondaryActionText"
      @click="addAnotherAccount">
      {{ secondaryActionText }}
    </blt-add-additional-button>

    <jha-button
      block
      v-if="secondaryActionText"
      :aria-label="secondaryActionText"
      sync
      tertiary>
    </jha-button>

    <jha-button
        block
        v-if="hasAnyTfaAccounts && focusedPane !== 'FUNDING_INTENTION'"
        sync
        @click="focusedPane = 'FUNDING_INTENTION'"
        tertiary>
      Show funding intentions
    </jha-button>
  </blt-emp-form>
</template>

<style scoped>
jha-slider-pane {
  animation: none;
}
</style>
