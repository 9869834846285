import type AxiosResponseInterceptor from "@/common/interfaces/axios/AxiosResponseInterceptor";
import type { AxiosError } from "axios";
import router from "@/branchmanager/router";
import { WorkspaceStatesConstant } from "@/branchmanager/app/constant/WorkspaceStatesConstant";
import inactivityTimeoutWorker from '@/branchmanager/app/worker/worker'
import { WorkerActions } from "../app/enums/workerActions";

const redirectErrors = new Set([
  'AUTH_APITOKEN_EXPIRED',
  'AUTH_APITOKEN_NOTVALID',
  'APITOKEN_IS_INVALID',
  'AUTH_APITOKEN_REVOKED',
  'AUTH_HEADER_MISSING_LOGIN',
])


export default class EmployeeChannelResponseInterceptor implements AxiosResponseInterceptor {
  onFulfilled<T, V>(response: V): Promise<T> | T {
    inactivityTimeoutWorker.postMessage({action: WorkerActions.RESET})
    return response as unknown as T;
  }

  onRejected(error: AxiosError): Promise<AxiosError> {
    function containsRedirectError(axiosError: AxiosError) {

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = axiosError.response as any;

      const errors = res?.data?.errors

      return Array.isArray(errors)
        && errors.some((it) => {
          return !!it.code && redirectErrors.has(it.code)
        })

    }

    if(containsRedirectError(error)) {
      router.push({name: WorkspaceStatesConstant.LOGIN.STATE, params: { timeout: "true" }})
    }

    return Promise.reject(error);
  }

}